<template>
  <div>
    <!--begin::Dashboard-->
    <div class="row">
      <div class="col-12" v-if="item">
        <b-card class="card card-custom card-stretch gutter-b" no-body>
            <!--begin::Layout-->
            <div class="d-flex flex-column flex-lg-row">
                <!--begin::Content-->
                <div class="flex-lg-row-fluid mb-10 mb-lg-0 me-lg-7 me-xl-10">
                    <!--begin::Card-->
                    <b-card class="p-0" border-variant="light">
                        <!--begin::Card body-->
                        <b-card-body class="p-0">

                            <b-button
                                v-if="!item.isRefund && !item.canceledBy"
                                @click.prevent="cancelInvoice"
                                type="button"
                                variant="warning"
                                class="float-right"
                                size="sm"
                            >
                                Annuler la Facture
                            </b-button>
                            <b-button
                                v-if="item.canceledBy"
                                variant="danger"
                                class="float-right"
                                style="cursor: default;"
                                size="sm"
                                disabled
                            >
                                Annulée
                            </b-button>

                            <h2>
                                Facture N°{{ item.numberFormatted }} {{ item.isRefund ? '(Avoir)' : '' }}
                                <small v-if="item.canceledBy" class="d-block">
                                    <router-link class="text-muted" :to="{ name: 'invoice', params: { id: item.canceledBy.id } }">
                                        Annulée par N°{{ item.canceledBy.numberFormatted }} <i class="fas fa-external-link-alt" />
                                    </router-link>
                                </small>
                            </h2>
                            
                            <!--begin::Wrapper-->
                            <div class="mb-0">
                                <!--begin::Table wrapper-->
                                <div class="table-responsive mb-10">
                                    <!--begin::Table-->
                                    <table class="table g-5 gs-0 mb-0 fw-bolder text-gray-700">
                                        <!--begin::Table head-->
                                        <thead>
                                            <tr class="border-bottom fs-7 fw-bolder text-gray-700 text-uppercase">
                                                <th class="min-w-100px w-100px">Référence</th>
                                                <th class="min-w-100px w-350px">Désignation</th>
                                                <th class="min-w-80px w-100px">Prix unit.</th>
                                                <th class="min-w-80px w-100px">Quantité</th>
                                                <th class="min-w-100px w-150px">TVA</th>
                                                <th class="min-w-100px w-150px text-end">Total</th>
                                            </tr>
                                        </thead>
                                        <!--end::Table head-->
                                        <!--begin::Table body-->
                                        <tbody>
                                            <tr v-for="(item, index) in item.lines" :key="`item-${index}`" class="border-bottom border-bottom-dashed">
                                                <td class="ps-0">{{ item.reference }}</td>
                                                <td class="ps-7">{{ item.description }}</td>
                                                <td class="ps-0">{{ item.price }}€</td>
                                                <td class="ps-0">{{ item.quantity }}</td>
                                                <td class="ps-0">{{ item.vat }}%</td>
                                                <td class="ps-0">{{ item.price * item.quantity }}€</td>
                                            </tr>
                                        </tbody>
                                        <!--end::Table body-->
                                        <!--begin::Table foot-->
                                        <tfoot v-if="item.lines.length">
                                            <tr class="border-top border-top-dashed align-top fs-6 fw-bolder text-gray-700">
                                                <th colspan="4"></th> 
                                                <th colspan="1" class="border-bottom border-bottom-dashed ps-0">
                                                    <div class="d-flex flex-column align-items-start">
                                                        <div class="fs-5">Total HT</div>
                                                    </div>
                                                </th>
                                                <th colspan="1" class="border-bottom border-bottom-dashed text-end">
                                                    <span>{{ Number(item.totalWithoutVat).toFixed(2) }}</span>€
                                                </th>
                                            </tr>
                                            <template v-if="item.discount">
                                                <tr class="border-top border-top-dashed align-top fs-6 fw-bolder text-gray-700">
                                                    <th colspan="4"></th> 
                                                    <th colspan="1" class="border-bottom border-bottom-dashed ps-0">
                                                        <div class="d-flex flex-column align-items-start">
                                                            <div class="fs-5">Remise ({{ item.discount }}%)</div>
                                                        </div>
                                                    </th>
                                                    <th colspan="1" class="border-bottom border-bottom-dashed text-end">
                                                        <span>-{{ Number(item.totalWithoutVat * (item.discount / 100)).toFixed(2) }}</span>€
                                                    </th>
                                                </tr>
                                            </template>
                                            <tr  v-for="(vatRate, idx) of item.vatRates" :key="`vat-rate-${idx}`" class="align-top fw-bolder text-gray-700">
                                                <th colspan="4"></th> 
                                                <th colspan="1" class="fs-4 ps-0">
                                                    TVA {{ vatRate.rate.toFixed(2) }}% <small>de {{ vatRate.baseAmount.toFixed(2) }}€</small>
                                                    <br>
                                                    <!-- <b-form-checkbox v-model="item.showTotalWithVat">
                                                        <small>Afficher la TVA</small>
                                                    </b-form-checkbox> -->
                                                </th>
                                                <th colspan="1" class="text-end fs-4 text-nowrap">
                                                    <span>{{ vatRate.vatAmount.toFixed(2) }}</span>€
                                                </th>
                                            </tr>
                                            <tr class="align-top fw-bolder text-gray-700">
                                                <th colspan="4"></th> 
                                                <th colspan="1" class="fs-4 ps-0">Total TTC</th>
                                                <th colspan="1" class="text-end fs-4 text-nowrap">
                                                    {{ item.totalWithVat.toFixed(2) }}€
                                                </th>
                                            </tr>
                                        </tfoot>
                                        <!--end::Table foot-->
                                    </table>
                                </div>
                                <!--end::Table-->
                            </div>
                            <!--end::Wrapper-->
                        </b-card-body>
                        <!--end::Card body-->
                    </b-card>
                    <!--end::Card-->
                </div>
                <!--end::Content-->
                <!--begin::Sidebar-->
                <div class="flex-lg-auto min-w-lg-300px">
                    <!--begin::Card-->
                    <b-card class="card" border-variant="light">
                        <!--begin::Card body-->
                        <b-card-body class="p-0">
                            <!--begin::Input group-->
                            <div class="mb-10">
                                <label class="form-label fs-6 fw-bolder text-gray-700 mb-3">Date de la vente</label>
                                <!--begin::Input group-->
                                <div class="mb-5">
                                    <input type="date" class="form-control form-control-solid" :value="invoiceDate" @input="onChangeInvoiceDate" />
                                </div>
                                <!--end::Input group-->
                            </div>
                            <!--end::Input group-->
                            <!--begin::Separator-->
                            <div class="separator separator-dashed mb-8"></div>
                            <!--end::Separator-->
                            <!--begin::Input group-->
                            <div class="mb-10">
                                <label class="form-label fs-6 fw-bolder text-gray-700 mb-3">Facturer à</label>
                                <!--begin::Input group-->
                                <div class="mb-5">
                                    <input v-model="item.customer.name" type="text" class="form-control form-control-solid" placeholder="Nom" />
                                </div>
                                <!--end::Input group-->
                                <!--begin::Input group-->
                                <div class="mb-5">
                                    <input v-model="item.customer.email" type="email" class="form-control form-control-solid" placeholder="Email" />
                                </div>
                                <!--end::Input group-->
                                <!--begin::Input group-->
                                <div class="mb-5">
                                    <textarea v-model="item.customer.address" name="notes" class="form-control form-control-solid" rows="3" placeholder="Adresse" />
                                </div>
                                <!--end::Input group-->
                                <!--begin::Input group-->
                                <div class="mb-5">
                                    <input v-model="item.customer.vatNumber" type="text" class="form-control form-control-solid" placeholder="N° de TVA (facultatif)" />
                                </div>
                                <!--end::Input group-->
                            </div>
                            <!--end::Input group-->
                            <!--begin::Separator-->
                            <div class="separator separator-dashed mb-8"></div>
                            <!--end::Separator-->
                            <!--begin::Actions-->
                            <div class="mb-0">

                                <b-button type="submit" @click.prevent="saveItem" variant="success" class="w-100 mb-5">
                                    Sauvegarder les modifications
                                </b-button>

                                <!--begin::Row-->
                                <div class="row">
                                    <!--begin::Col-->
                                    <div class="col">
                                        <b-button v-if="pdfLoading" variant="outline-primary" class="w-100" disabled>
                                            <b-spinner small type="grow" /> PDF
                                        </b-button>
                                        <b-button v-else @click.prevent="exportPdf" type="button" variant="outline-primary" class="w-100">
                                            <i class="fas fa-file-pdf mr-3" /> PDF
                                        </b-button>
                                    </div>
                                    <!--end::Col-->
                                    <!--begin::Col-->
                                    <div class="col">
                                        <b-button v-if="emailLoading" variant="outline-primary" class="w-100" disabled>
                                            <b-spinner small type="grow" /> Email
                                        </b-button>
                                        <b-button v-else @click.prevent="sendEmail" type="button" variant="outline-primary" class="w-100">
                                            <i class="fas fa-paper-plane mr-3" /> Email
                                        </b-button>
                                    </div>
                                    <!--end::Col-->
                                </div>
                                <!--end::Row-->
                            </div>
                            <!--end::Actions-->
                        </b-card-body>
                        <!--end::Card body-->
                    </b-card>
                    <!--end::Card-->
                </div>
                <!--end::Sidebar-->
            </div>
            <!--end::Layout-->
        </b-card>
      </div>
    </div>
    <!--end::Dashboard-->

  </div>
</template>

<script>
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import ApiService from "@/core/services/api.service";

export default {
  name: "invoice",
  data() {
    return {
      item: null,
      pdfLoading: false,
      emailLoading: false,
    };
  },
  computed: {
    invoiceDate() {
        return new Date(this.item.invoiceDate).toISOString().split('T')[0];
    },
  },
  watch: {
    // call again the method if the route changes
    '$route': 'init'
  },
  mounted() {
    this.init();
  },
  methods: {
    init() {
        this.$store.dispatch(SET_BREADCRUMB, [{ title: "Factures", route: { name: "invoices" } }, { title: "Facture" }]);
        this.$router.onReady(() => {
            this.fetchItem(this.$route.params.id);
        });
    },
    onChangeInvoiceDate(e) {
        try {
            const val = e.target.value;
            this.item.invoiceDate = new Date(val);
        } catch (err) {
            console.error(err);
        }
    },
    /**
     * Fetch invoice
     */
    fetchItem(id) {
        ApiService
            .get('/invoices', id)
            .then(({ data }) => {
                this.item = data;
            })
            .catch(() => {
                alert('Facture introuvable');
            });
    },
    /**
     * Save invoice parameters
     */
    saveItem() {
        ApiService
            .update('/invoices', this.item.id, this.item)
            .then(() => {
                this.fetchItem(this.item.id);
                this.$swal('Facture mise à jour', '', 'success');
            });
    },
    /**
     * Export invoice PDF
     */
    exportPdf() {
        this.pdfLoading = true;
        ApiService
            .query(`/invoices/${this.item.id}/pdf`, { responseType: 'arraybuffer' })
            .then(({ data }) => {
                const blob = new Blob([data], { type: 'application/pdf' } );
                const url = window.URL.createObjectURL(blob);
                const link = document.createElement('a');
                link.href = url;
                link.setAttribute('download', `facture-${this.item.numberFormatted}.pdf`);
                document.body.appendChild(link);
                link.click();
                this.pdfLoading = false;
            })
            .catch((err) => {
                console.error(err);
                this.pdfLoading = false;
            });
    },
    /**
     * Send invoice by email
     */
    sendEmail() {
        this.emailLoading = true;
        ApiService
            .post(`/invoices/${this.item.id}/email`)
            .then(() => {
                this.$swal(`Facture envoyée à ${this.item.customer.email}`, '', 'success');
                this.emailLoading = false;
            })
            .catch((err) => {
                console.error(err);
                this.emailLoading = false;
            });
    },
    /**
     * Cancel invoice and generate its following "cancelled" version
     */
    cancelInvoice() {
        this.$swal.fire({
            title: 'Êtes-vous sûr de vouloir annuler cette facture ?',
            text: "Cette opération entraînera la génération automatique d'un avoir et annulera la vente/PEC associée, le cas échéant",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#FDA702',
            confirmButtonText: 'Confirmer',
        }).then(({ isConfirmed }) => {
            if (isConfirmed) {
                ApiService
                    .post(`/invoices/${this.item.id}/cancel`)
                    .then(({ data }) => {
                        this.$router.push({ name: 'invoice', params: { id: data.id } });
                    })
                    .catch((err) => {
                        console.error(err);
                    });
            }
        });
    },
  },
};
</script>